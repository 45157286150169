import * as React from "react"
//import component list
import GlobalIntro from '../components/global-intro'
import BlogFeed from '../components/blog-feed'
import GlobalDuplexBanner from '../components/global-duplex-banner'

const ContentArea = ({layoutPieces}) => {
    if (layoutPieces) {
      return layoutPieces?.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulGlobalIntro' && <GlobalIntro layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulBlogPosts' && <BlogFeed layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulDuplexFeature' && <GlobalDuplexBanner layoutPiece={layoutPiece} key={index}/>,
        ])
      })
    }
}

export default ContentArea
