import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import BlogContentArea from '../templates/blog-content-area'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function BlogPage({ data, pageContext }) {
  const layoutPieces = data.contentfulBlogPage.layoutPieces
  return (
    <Layout header={data?.contentfulBlogPage?.header} footer={data?.contentfulBlogPage?.footer}>
      <BlogContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <meta property="og:title" content={data?.contentfulBlogPage?.metadata?.ogMetaTitle ? data?.contentfulBlogPage?.metadata?.ogMetaTitle : "Entrata | Blog"} />
    <meta property="og:description" content={data?.contentfulBlogPage?.metadata?.ogMetaDescription ? data?.contentfulBlogPage?.metadata?.ogMetaDescription : "Official insights and announcements from the people at Entrata"} />
    <meta property="og:url" content="https://www.entrata.com/blog" />
    <meta name="description" content={data?.contentfulBlogPage?.metadata?.metaDescription ? data?.contentfulBlogPage?.metadata?.metaDescription : "Official insights and announcements from the people at Entrata"} />
    <title>Entrata | Blog</title>
    <meta property="og:image" content={data?.contentfulBlogPage?.metadata?.ogMetaImage?.url ? data?.contentfulBlogPage?.metadata?.ogMetaImage?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query BlogPage {
    contentfulBlogPage {
      header
      footer
      metadata {
        metaDescription
        metaTitle
        ogMetaDescription
        ogMetaImage {
          url
        }
        ogMetaTitle
        ogMetaType
      }
      layoutPieces {
        ... on Node {
          internal {
            type
          }
        }
      }
      layoutPieces {
        ... on ContentfulDuplexFeature {
          ...DuplexFeature
        }
        ... on ContentfulGlobalIntro {
          introText
          introTitle
          introButton {
            buttonOutlined
            buttonText
            slug
            fullUrl
          }
        }
        ... on ContentfulBlogPosts {
          ...BlogFeed
        }
      }
    }
  }
`
